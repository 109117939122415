import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { sections } from '../../content/landing/sections';

const FooterContainer = styled.footer`
  background: ${({ theme }) => theme.colors.backgroundDark};
  padding: ${({ theme }) => theme.space['4xl']} 0;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: ${({ theme }) => theme.gradients.primary};
    opacity: 0.1;
  }
`;

const FooterContent = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.space['4xl']};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.space['2xl']};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 ${({ theme }) => theme.space.xl};
  }
`;

const Logo = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  font-weight: ${({ theme }) => theme.fontWeights.extrabold};
  background: ${({ theme }) => theme.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: ${({ theme }) => theme.space.xl};
  letter-spacing: -1px;
`;

const LinksContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space['2xl']};
  margin-bottom: ${({ theme }) => theme.space.xl};

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
    flex-direction: column;
    align-items: center;
    gap: ${({ theme }) => theme.space.lg};
  }
`;

const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.colors.textSecondary};
  text-decoration: none;
  font-size: ${({ theme }) => theme.fontSizes.md};
  transition: all ${({ theme }) => theme.transitions.normal};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 100%;
    height: 1px;
    background: ${({ theme }) => theme.gradients.primary};
    transform: scaleX(0);
    transform-origin: right;
    transition: transform ${({ theme }) => theme.transitions.normal};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};

    &::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }
`;

const Copyright = styled.div`
  color: ${({ theme }) => theme.colors.textMuted};
  font-size: ${({ theme }) => theme.fontSizes.sm};
  text-align: center;
`;

const CommonFooter: React.FC = () => {
  const { footer } = sections;
  const year = new Date().getFullYear();

  return (
    <FooterContainer>
      <FooterContent>
        <Logo>PROSPECTORIA</Logo>
        <LinksContainer>
          {footer.links.map((link, index) => (
            <FooterLink key={index} to={link.href}>
              {link.text}
            </FooterLink>
          ))}
        </LinksContainer>
        <Copyright>
          {footer.copyright.replace('{year}', year.toString())}
        </Copyright>
      </FooterContent>
    </FooterContainer>
  );
};

export default CommonFooter;
