import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Nav = styled.nav<{ $isLandingPage?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  background: ${({ theme, $isLandingPage }) => 
    $isLandingPage 
      ? 'transparent' 
      : `linear-gradient(to bottom, ${theme.colors.backgroundDark}, transparent)`};
  backdrop-filter: ${({ $isLandingPage }) => 
    $isLandingPage ? 'none' : 'blur(10px)'};
  z-index: ${({ theme }) => theme.zIndices.banner};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${({ theme }) => theme.space['4xl']};
  transition: all ${({ theme }) => theme.transitions.normal};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    padding: 0 ${({ theme }) => theme.space.xl};
  }
`;

export const NavSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space['2xl']};
`;

export const Logo = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  font-weight: ${({ theme }) => theme.fontWeights.extrabold};
  background: ${({ theme }) => theme.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  transition: all ${({ theme }) => theme.transitions.normal};
  letter-spacing: -1px;

  &:hover {
    transform: translateY(-1px);
    filter: brightness(1.2);
  }
`;

export const MainNav = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.xl};
  margin-left: ${({ theme }) => theme.space['2xl']};

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    display: none;
  }
`;

export const NavLink = styled(Link)<{ $isActive: boolean }>`
  color: ${({ theme, $isActive }) => 
    $isActive ? theme.colors.primary : theme.colors.textSecondary};
  text-decoration: none;
  font-family: ${({ theme }) => theme.fonts.body};
  font-weight: ${({ theme, $isActive }) => 
    $isActive ? theme.fontWeights.bold : theme.fontWeights.medium};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  letter-spacing: -0.5px;
  padding: ${({ theme }) => `${theme.space.xs} ${theme.space.sm}`};
  border-radius: ${({ theme }) => theme.radii.md};
  transition: all ${({ theme }) => theme.transitions.normal};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 0;
    height: 2px;
    background: ${({ theme }) => theme.gradients.primary};
    transition: all ${({ theme }) => theme.transitions.normal};
    transform: translateX(-50%);
    opacity: 0;
  }

  &:hover {
    color: ${({ theme }) => theme.colors.primary};
    transform: translateY(-1px);

    &::after {
      width: 100%;
      opacity: 1;
    }
  }
`;

export const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.lg};
`;

export const EarlyAdopterButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.sm};
  padding: ${({ theme }) => `${theme.space.sm} ${theme.space.xl}`};
  background: ${({ theme }) => theme.gradients.primary};
  border: none;
  border-radius: ${({ theme }) => theme.radii.full};
  color: ${({ theme }) => theme.colors.background};
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: ${({ theme }) => theme.fontSizes.md};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  cursor: pointer;
  transition: all ${({ theme }) => theme.transitions.normal};
  text-transform: uppercase;
  letter-spacing: 0.5px;
  backdrop-filter: blur(5px);

  svg {
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ theme }) => theme.shadows.glow.primary};
  }

  &:active {
    transform: translateY(1px);
  }
`;
