import { LandingContent } from './types';

export const sections: LandingContent['sections'] = {
  hero: {
    title: 'PROSPECTORIA',
    subtitle: 'L\'Intelligence Artificielle au service de votre prospection immobilière. Trouvez vos pépites avant les autres.',
    cta: {
      loggedOut: 'Rejoindre les pionniers',
      loggedIn: {
        free: 'Rejoindre les pionniers',
        premium: 'Rejoindre les pionniers'
      }
    },
    launchOffer: {
      text: 'Offre Early Adopter : -40% à vie pour les 1000 premiers',
      visibleTo: 'all'
    },
    visual: {
      type: 'illustration',
      description: 'Interface futuriste de prospection immobilière'
    }
  },

  stats: {
    number: '94259747',
    text: 'PARCELLES ANALYSÉES',
    subtext: 'La plus grande base de données immobilière de France, mise à jour en temps réel',
    icon: '📊'
  },

  features: {
    title: 'LE FUTUR EST LÀ',
    blocks: [
      {
        title: 'Recherche Ultra-Précise',
        description: 'Notre IA analyse surface, localisation et potentiel en temps réel. Trouvez vos pépites immobilières en quelques clics.',
        icon: '🎯',
        isPremium: false
      },
      {
        title: 'Assistant IA Nouvelle Génération',
        description: 'Votre copilote IA génère annonces, lettres et offres professionnelles en quelques secondes. Gagnez des heures chaque jour.',
        icon: '🤖',
        isPremium: true
      },
      {
        title: 'Suivi Intelligent',
        description: 'Ne manquez plus aucune opportunité. Notre système de suivi intelligent vous alerte en temps réel des meilleures occasions.',
        icon: '📋',
        isPremium: true
      },
      {
        title: 'Street View Intégré',
        description: 'Évaluez instantanément le potentiel d\'un bien. Visualisation immersive et analyse contextuelle en un clic.',
        icon: '🌍',
        isPremium: false
      }
    ]
  },

  preview: {
    title: 'OFFRE LIMITÉE',
    text: 'Les 1000 premiers bénéficient d\'avantages exceptionnels : tarif préférentiel à vie, accès prioritaire aux nouvelles fonctionnalités, et formation exclusive offerte.',
    cta: {
      loggedOut: 'Réserver mon accès privilégié',
      loggedIn: {
        free: 'Réserver mon accès privilégié',
        premium: 'Réserver mon accès privilégié'
      }
    }
  },

  pricing: {
    title: 'OFFRE EARLY ADOPTER',
    text: 'Positionnez-vous parmi les leaders de demain',
    visibleTo: 'all',
    plans: {
      free: {
        title: 'Accès Standard',
        features: [
          'Bientôt disponible à 49.99€/mois',
          'Recherche de base',
          'Visualisation simple',
          'Suivi limité'
        ],
        cta: {
          loggedOut: 'Rejoindre la liste d\'attente',
          loggedIn: {
            free: 'Rejoindre la liste d\'attente',
            premium: 'Rejoindre la liste d\'attente'
          }
        }
      },
      premium: {
        title: 'Early Adopter Premium',
        price: '29.99€/mois',
        features: [
          'Tarif garanti à vie (économisez 240€/an)',
          'Accès prioritaire aux nouvelles fonctionnalités',
          'Formation exclusive offerte (valeur 499€)',
          'Support premium dédié 7j/7',
          'Fonctionnalités avancées en avant-première'
        ],
        cta: {
          loggedOut: 'Réserver mon tarif early adopter',
          loggedIn: {
            free: 'Réserver mon tarif early adopter',
            premium: 'Réserver mon tarif early adopter'
          }
        },
        note: 'Plus que quelques places disponibles au tarif early adopter'
      }
    }
  },

  trust: {
    title: 'POURQUOI NOUS FAIRE CONFIANCE',
    text: 'Développé avec et pour les professionnels de l\'immobilier',
    indicators: [
      'Base de données mise à jour en temps réel',
      'Sécurité et confidentialité garanties',
      'Support réactif 7j/7',
      'Tarif early adopter garanti à vie'
    ]
  },

  finalCta: {
    title: 'REJOIGNEZ LES PIONNIERS',
    text: 'Plus que quelques places disponibles au tarif early adopter. Ne manquez pas cette opportunité unique de transformer votre approche de la prospection immobilière.',
    cta: {
      loggedOut: 'Je sécurise mon accès maintenant',
      loggedIn: {
        free: 'Je sécurise mon accès maintenant',
        premium: 'Je sécurise mon accès maintenant'
      }
    },
    visibleTo: 'all'
  },

  // Sections requises par le type mais non affichées dans le teaser
  targetAudience: {
    title: 'Pour qui ?',
    items: [
      {
        icon: '🏢',
        title: 'Agents Immobiliers',
        description: 'Optimisez votre prospection'
      },
      {
        icon: '📈',
        title: 'Investisseurs',
        description: 'Trouvez les meilleures opportunités'
      },
      {
        icon: '🔍',
        title: 'Promoteurs',
        description: 'Identifiez les terrains à potentiel'
      }
    ]
  },

  problem: {
    subtitle: 'La prospection simplifiée',
    text: 'Fini les heures perdues en recherches',
    visual: {
      type: 'animation',
      description: 'Démonstration'
    }
  },

  testimonials: {
    title: 'Ils nous font confiance',
    items: [
      {
        quote: 'Une approche révolutionnaire',
        author: 'Marie L.',
        role: 'Agent Immobilier'
      },
      {
        quote: 'Un outil indispensable',
        author: 'Thomas D.',
        role: 'Investisseur'
      }
    ]
  },

  coConstruction: {
    title: 'Évoluons ensemble',
    text: 'Votre avis compte',
    visual: {
      type: 'illustration',
      description: 'Collaboration'
    }
  },

  footer: {
    links: [
      { text: 'CGV', href: '/cgv', category: 'legal' },
      { text: 'Confidentialité', href: '/confidentialite', category: 'legal' },
      { text: 'FAQ', href: '/faq', category: 'info' },
      { text: 'Contact', href: '/contact', category: 'info' }
    ],
    copyright: '© {year} PROSPECTORIA. Tous droits réservés.'
  }
};
