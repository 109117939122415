import React, { useState } from 'react';
import styled from 'styled-components';
import LegalLayout, { Section } from '../../components/LegalLayout';

const CookieToggle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: ${({ theme }) => theme.colors.light};
  border-radius: 8px;
  margin-bottom: 15px;
`;

const CookieInfo = styled.div`
  flex: 1;
`;

const CookieName = styled.h3`
  margin: 0 0 5px 0;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 1.1rem;
`;

const CookieDescription = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.secondary};
  font-size: 0.9rem;
`;

const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 20px;
`;

const Slider = styled.span<{ $isOn?: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.$isOn ? props.theme.colors.primary : '#ccc'};
  transition: .4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
    transform: ${props => props.$isOn ? 'translateX(26px)' : 'translateX(0)'};
  }
`;

const SaveButton = styled.button`
  padding: 12px 24px;
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-top: 20px;

  &:hover {
    background: ${({ theme }) => theme.colors.primary}dd;
    transform: translateY(-2px);
  }
`;

interface CookieSettings {
  necessary: boolean;
  analytics: boolean;
  marketing: boolean;
  preferences: boolean;
}

const CookiesPage: React.FC = () => {
  const [settings, setSettings] = useState<CookieSettings>({
    necessary: true,
    analytics: true,
    marketing: false,
    preferences: true
  });

  const handleSave = () => {
    // TODO: Implémenter la sauvegarde des préférences
    localStorage.setItem('cookieSettings', JSON.stringify(settings));
    alert('Vos préférences ont été enregistrées.');
  };

  return (
    <LegalLayout title="Gestion des Cookies">
      <Section>
        <h2>À propos des cookies</h2>
        <p>
          Les cookies sont de petits fichiers texte stockés sur votre appareil qui nous aident à améliorer votre expérience sur notre site.
          Vous pouvez choisir les types de cookies que vous acceptez.
        </p>
      </Section>

      <Section>
        <h2>Paramètres des cookies</h2>
        
        <CookieToggle>
          <CookieInfo>
            <CookieName>Cookies nécessaires</CookieName>
            <CookieDescription>
              Indispensables au fonctionnement du site. Ne peuvent pas être désactivés.
            </CookieDescription>
          </CookieInfo>
          <Switch>
            <Slider $isOn={true} />
          </Switch>
        </CookieToggle>

        <CookieToggle>
          <CookieInfo>
            <CookieName>Cookies analytiques</CookieName>
            <CookieDescription>
              Nous aident à comprendre comment vous utilisez le site.
            </CookieDescription>
          </CookieInfo>
          <Switch onClick={() => setSettings(prev => ({ ...prev, analytics: !prev.analytics }))}>
            <Slider $isOn={settings.analytics} />
          </Switch>
        </CookieToggle>

        <CookieToggle>
          <CookieInfo>
            <CookieName>Cookies marketing</CookieName>
            <CookieDescription>
              Utilisés pour le ciblage publicitaire personnalisé.
            </CookieDescription>
          </CookieInfo>
          <Switch onClick={() => setSettings(prev => ({ ...prev, marketing: !prev.marketing }))}>
            <Slider $isOn={settings.marketing} />
          </Switch>
        </CookieToggle>

        <CookieToggle>
          <CookieInfo>
            <CookieName>Cookies de préférences</CookieName>
            <CookieDescription>
              Permettent de mémoriser vos préférences d'utilisation.
            </CookieDescription>
          </CookieInfo>
          <Switch onClick={() => setSettings(prev => ({ ...prev, preferences: !prev.preferences }))}>
            <Slider $isOn={settings.preferences} />
          </Switch>
        </CookieToggle>

        <SaveButton onClick={handleSave}>
          Enregistrer mes préférences
        </SaveButton>
      </Section>

      <Section>
        <h2>Utilisation des cookies</h2>
        <ul>
          <li>Cookies nécessaires : authentification, sécurité</li>
          <li>Cookies analytiques : statistiques de visite, performance</li>
          <li>Cookies marketing : publicités personnalisées</li>
          <li>Cookies de préférences : langue, thème, paramètres</li>
        </ul>
      </Section>

      <Section>
        <h2>Durée de conservation</h2>
        <ul>
          <li>Cookies de session : supprimés à la fermeture du navigateur</li>
          <li>Cookies persistants : jusqu'à 13 mois maximum</li>
          <li>Préférences de cookies : 6 mois</li>
        </ul>
      </Section>
    </LegalLayout>
  );
};

export default CookiesPage;
