import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import AOS from 'aos';
import 'aos/dist/aos.css';
import AppRoutes from './routes';
import { AuthProvider } from './contexts/AuthContext';
import { app } from './config/firebase';
import { theme } from './styles/theme';
import { GlobalStyle } from './styles/globalStyles';
import Layout from './components/Layout';
import SplashScreen from './components/SplashScreen';

const App: React.FC = () => {
  const [isLoading, setIsLoading] = React.useState(true);

  useEffect(() => {
    // Initialisation de AOS
    AOS.init({
      duration: 1000,
      once: true,
      offset: 100,
      easing: 'ease-out-cubic',
      disable: 'mobile' // Désactive sur mobile pour de meilleures performances
    });

    // Réinitialise AOS sur les changements de route
    window.addEventListener('load', () => {
      AOS.refresh();
    });
  }, []);

  const handleSplashScreenFinish = () => {
    setIsLoading(false);
  };

  if (isLoading) {
    return <SplashScreen onFinish={handleSplashScreenFinish} />;
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Layout>
            <AppRoutes />
          </Layout>
        </ThemeProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
