import { sections } from './sections';
import type { LandingContent } from './types';

// Export du contenu principal utilisé par LandingPage
export const landingContent: LandingContent = {
  sections
};

// Export des types
export type { LandingContent } from './types';

// Structure de la landing page pour documentation
export const landingStructure = {
  sections: [
    {
      id: 'hero',
      name: 'Hero',
      description: 'Section d\'accueil avec proposition de valeur et CTA'
    },
    {
      id: 'stats',
      name: 'Statistiques',
      description: 'Mise en avant des chiffres clés'
    },
    {
      id: 'problem',
      name: 'Le Problème & La Solution',
      description: 'Présentation du problème résolu par PROSPECTORIA'
    },
    {
      id: 'features',
      name: 'Fonctionnalités Clés',
      description: 'Des Outils Pensés pour les Pros'
    },
    {
      id: 'preview',
      name: 'Aperçu',
      description: 'Mode démo sans inscription'
    },
    {
      id: 'pricing',
      name: 'Tarifs & Plans',
      description: 'Comparaison des offres disponibles'
    },
    {
      id: 'testimonials',
      name: 'Témoignages Pros',
      description: 'Retours d\'expérience utilisateurs'
    },
    {
      id: 'coConstruction',
      name: 'Co-Construction',
      description: 'Participation à l\'évolution du produit'
    },
    {
      id: 'trust',
      name: 'Confiance & Support',
      description: 'Indicateurs de fiabilité et support'
    },
    {
      id: 'finalCta',
      name: 'Appel à l\'Action Final',
      description: 'Rappel de l\'offre et invitation à l\'action'
    }
  ],
  
  metadata: {
    version: '2.0.0',
    lastUpdate: '2023-12-01',
    launchOfferEndDate: '2023-12-31'
  }
};
