import { 
    doc, 
    getDoc, 
    setDoc, 
    updateDoc, 
    increment, 
    Timestamp,
    collection,
    query,
    where,
    orderBy,
    limit,
    getDocs,
    writeBatch,
    DocumentData,
    QueryDocumentSnapshot
} from 'firebase/firestore';
import { db, auth } from '../config/firebase';
import { USER_LIMITS } from '../config/limits';
import { SearchCriteria } from '../pages/SearchPage/types';
import { UserStats, ProspectionStatus } from '../types/prospection';

// Interfaces
export interface UserData {
    searchCount: number;
    isPremium: boolean;
    favorites: string[];
    lastResetDate?: Timestamp;
    quotaResetTime?: Timestamp;
    savedSearches?: string[];
    createdAt: Timestamp;
    lastLoginAt: Timestamp;
    email: string;
    displayName?: string;
}

export interface SearchHistoryItem {
    id: string;
    userId: string;
    date: Timestamp;
    criteria: SearchCriteria;
    resultsCount: number;
    totalParcels: number;
    mode: 'recherche-ciblee' | 'recherche-generale';
    commune: string;
    success: boolean;
}

export interface ProspectData {
    id: string;
    userId: string;
    address: string;
    surface: number;
    status: ProspectionStatus;
    createdAt: Timestamp;
    updatedAt: Timestamp;
    notes?: string[];
    commune: string;
    coordinates: {
        lat: number;
        lng: number;
    };
}

// Constants
const COLLECTIONS = {
    USERS: 'users',
    SEARCHES: 'searches',
    PROSPECTS: 'prospects',
    COMMENTS: 'comments'
} as const;

// Utility Functions
const getNextResetTime = (): Timestamp => {
    const now = new Date();
    const tomorrow = new Date(now);
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return Timestamp.fromDate(tomorrow);
};

const isNewDay = (date1: Date, date2: Date): boolean => {
    return date1.getDate() !== date2.getDate() ||
           date1.getMonth() !== date2.getMonth() ||
           date1.getFullYear() !== date2.getFullYear();
};

const checkLimit = (currentCount: number, isPremium: boolean, type: 'favorites' | 'searchesPerDay' | 'prospects'): boolean => {
    const limits = isPremium ? USER_LIMITS.PREMIUM : USER_LIMITS.FREE;
    switch (type) {
        case 'favorites':
            return currentCount >= limits.maxFavorites;
        case 'searchesPerDay':
            return currentCount >= limits.searchesPerDay;
        case 'prospects':
            return currentCount >= limits.maxProspects;
        default:
            return false;
    }
};

class UserService {
    // User Data Management
    async getUserData(userId: string): Promise<UserData> {
        const userRef = doc(db, COLLECTIONS.USERS, userId);
        const userDoc = await getDoc(userRef);

        if (!userDoc.exists()) {
            const defaultData: UserData = {
                searchCount: 0,
                isPremium: false,
                favorites: [],
                lastResetDate: Timestamp.now(),
                quotaResetTime: getNextResetTime(),
                savedSearches: [],
                createdAt: Timestamp.now(),
                lastLoginAt: Timestamp.now(),
                email: auth.currentUser?.email || ''
            };
            await setDoc(userRef, defaultData);
            return defaultData;
        }

        const userData = userDoc.data() as UserData;
        
        // Check if quotas need to be reset
        const now = Timestamp.now();
        if (userData.lastResetDate && isNewDay(userData.lastResetDate.toDate(), now.toDate())) {
            const updatedData = {
                ...userData,
                searchCount: 0,
                lastResetDate: now,
                quotaResetTime: getNextResetTime(),
                lastLoginAt: now
            };
            await updateDoc(userRef, updatedData);
            return updatedData;
        }

        return userData;
    }

    async updateUserData(userId: string, data: Partial<UserData>): Promise<void> {
        const userRef = doc(db, COLLECTIONS.USERS, userId);
        await updateDoc(userRef, {
            ...data,
            updatedAt: Timestamp.now()
        });
    }

    // Search Management
    async incrementSearchCount(userId: string): Promise<boolean> {
        const userRef = doc(db, COLLECTIONS.USERS, userId);
        const userData = await this.getUserData(userId);

        if (checkLimit(userData.searchCount, userData.isPremium, 'searchesPerDay')) {
            return false;
        }

        await updateDoc(userRef, {
            searchCount: increment(1),
            lastSearchAt: Timestamp.now()
        });

        return true;
    }

    async saveSearchToHistory(
        userId: string,
        criteria: SearchCriteria,
        resultsCount: number,
        totalParcels: number,
        mode: 'recherche-ciblee' | 'recherche-generale'
    ): Promise<string> {
        const searchesRef = collection(db, COLLECTIONS.SEARCHES);
        const searchData: Omit<SearchHistoryItem, 'id'> = {
            userId,
            date: Timestamp.now(),
            criteria,
            resultsCount,
            totalParcels,
            mode,
            commune: criteria.commune,
            success: resultsCount > 0
        };

        const docRef = doc(searchesRef);
        await setDoc(docRef, searchData);
        return docRef.id;
    }

    // Statistics and Analytics
    async getUserStats(userId: string): Promise<UserStats> {
        try {
            // Get user data
            const userData = await this.getUserData(userId);

            // Get search history
            const searchesRef = collection(db, COLLECTIONS.SEARCHES);
            const searchesQuery = query(
                searchesRef,
                where('userId', '==', userId),
                orderBy('date', 'desc')
            );
            const searchesSnapshot = await getDocs(searchesQuery);
            const searches = searchesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            })) as SearchHistoryItem[];

            // Get prospects
            const prospectsRef = collection(db, COLLECTIONS.PROSPECTS);
            const prospectsQuery = query(
                prospectsRef,
                where('userId', '==', userId)
            );
            const prospectsSnapshot = await getDocs(prospectsQuery);
            const prospects = prospectsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            })) as ProspectData[];

            // Calculate city statistics
            const cityCounts = searches.reduce((acc, search) => {
                const city = search.commune;
                acc[city] = (acc[city] || 0) + 1;
                return acc;
            }, {} as Record<string, number>);

            const mostSearchedCity = Object.entries(cityCounts)
                .sort(([,a], [,b]) => b - a)
                .map(([name, count]) => ({ name, count }))[0] || { name: '-', count: 0 };

            // Calculate daily averages
            const now = new Date();
            const oneDayAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000);
            const searchesLastDay = searches.filter(search => 
                search.date.toDate() > oneDayAgo
            ).length;

            // Calculate mode statistics
            const searchesByMode = searches.reduce((acc, search) => {
                acc[search.mode] = (acc[search.mode] || 0) + 1;
                return acc;
            }, {} as Record<'recherche-ciblee' | 'recherche-generale', number>);

            // Calculate prospect statistics
            const prospectsByStatus = prospects.reduce((acc, prospect) => {
                acc[prospect.status] = (acc[prospect.status] || 0) + 1;
                return acc;
            }, {} as Record<ProspectionStatus, number>);

            return {
                totalSearches: searches.length,
                successfulSearches: searches.filter(s => s.success).length,
                savedProspects: prospects.length,
                activeProspects: prospects.filter(p => p.status === 'en-cours').length,
                lastSearchDate: searches[0]?.date.toDate(),
                averageSearchesPerDay: searchesLastDay,
                mostSearchedCity,
                prospectsByStatus,
                searchesByMode: {
                    'recherche-ciblee': searchesByMode['recherche-ciblee'] || 0,
                    'recherche-generale': searchesByMode['recherche-generale'] || 0
                }
            };
        } catch (error) {
            console.error('Erreur lors de la récupération des statistiques:', error);
            return {
                totalSearches: 0,
                successfulSearches: 0,
                savedProspects: 0,
                activeProspects: 0,
                averageSearchesPerDay: 0,
                searchesByMode: {
                    'recherche-ciblee': 0,
                    'recherche-generale': 0
                }
            };
        }
    }

    // Account Management
    async deleteAccount(userId: string): Promise<void> {
        try {
            const user = auth.currentUser;
            if (!user || user.uid !== userId) {
                throw new Error('Utilisateur non authentifié ou ID incorrect');
            }

            const batch = writeBatch(db);

            // Delete searches
            const searchesRef = collection(db, COLLECTIONS.SEARCHES);
            const searchesQuery = query(searchesRef, where('userId', '==', userId));
            const searchesSnapshot = await getDocs(searchesQuery);
            searchesSnapshot.docs.forEach(doc => {
                batch.delete(doc.ref);
            });

            // Delete prospects
            const prospectsRef = collection(db, COLLECTIONS.PROSPECTS);
            const prospectsQuery = query(prospectsRef, where('userId', '==', userId));
            const prospectsSnapshot = await getDocs(prospectsQuery);
            prospectsSnapshot.docs.forEach(doc => {
                batch.delete(doc.ref);
            });

            // Delete user document
            const userRef = doc(db, COLLECTIONS.USERS, userId);
            batch.delete(userRef);

            // Execute batch
            await batch.commit();

            // Delete Firebase Auth account
            await user.delete();
        } catch (error) {
            console.error('Erreur lors de la suppression du compte:', error);
            throw new Error('Erreur lors de la suppression du compte');
        }
    }

    // Premium Management
    async upgradeToPremium(userId: string): Promise<void> {
        const userRef = doc(db, COLLECTIONS.USERS, userId);
        await updateDoc(userRef, {
            isPremium: true,
            searchCount: 0,
            lastResetDate: Timestamp.now(),
            quotaResetTime: getNextResetTime(),
            upgradedAt: Timestamp.now()
        });
    }

    async resetQuota(userId: string): Promise<void> {
        const userRef = doc(db, COLLECTIONS.USERS, userId);
        await updateDoc(userRef, {
            searchCount: 0,
            lastResetDate: Timestamp.now(),
            quotaResetTime: getNextResetTime()
        });
    }
}

export const userService = new UserService();
