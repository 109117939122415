import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import styled from 'styled-components';

const PageContainer = styled.div`
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.background};
  padding: 80px 20px;
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const Header = styled.header`
  background: white;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: 16px;
  transition: transform 0.2s;

  &:hover {
    transform: translateX(-5px);
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: 30px;
  font-size: 2rem;
`;

export const Section = styled.section`
  margin-bottom: 40px;

  h2 {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 20px;
    font-size: 1.5rem;
  }

  p {
    color: ${({ theme }) => theme.colors.secondary};
    line-height: 1.6;
    margin-bottom: 15px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      color: ${({ theme }) => theme.colors.secondary};
      line-height: 1.6;
      margin-bottom: 10px;
      padding-left: 20px;
      position: relative;

      &::before {
        content: "•";
        color: ${({ theme }) => theme.colors.primary};
        position: absolute;
        left: 0;
      }
    }
  }
`;

interface LegalLayoutProps {
  title: string;
  children: ReactNode;
}

export const LegalLayout: React.FC<LegalLayoutProps> = ({ title, children }) => {
  const navigate = useNavigate();

  return (
    <>
      <Header>
        <BackButton onClick={() => navigate('/')}>
          <FaArrowLeft /> Retour à l'accueil
        </BackButton>
      </Header>

      <PageContainer>
        <Content>
          <Title>{title}</Title>
          {children}
        </Content>
      </PageContainer>
    </>
  );
};

export default LegalLayout;
