import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const rocketAnimation = keyframes`
  0% {
    transform: translateY(100vh) scale(0.5);
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  50% {
    transform: translateY(30vh) scale(1);
  }
  80% {
    transform: translateY(-20vh) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translateY(-100vh) scale(0.5);
    opacity: 0;
  }
`;

const textReveal = keyframes`
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
`;

const Container = styled.div<{ isExiting: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(135deg, #0a2463, ${({ theme }) => theme.colors.primary});
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  animation: ${({ isExiting }) => isExiting ? fadeOut : 'none'} 0.5s ease-out forwards;
`;

const RocketContainer = styled.div`
  font-size: 4rem;
  animation: ${rocketAnimation} 3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.space.md};
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-size: ${({ theme }) => theme.fontSizes.xxxl};
  font-weight: bold;
  opacity: 0;
  animation: ${textReveal} 0.5s ease-out forwards;
  animation-delay: 0.5s;
  text-align: center;
  margin-top: ${({ theme }) => theme.space.lg};
`;

const Subtitle = styled.div`
  color: #ffd700;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  opacity: 0;
  animation: ${textReveal} 0.5s ease-out forwards;
  animation-delay: 1s;
  text-align: center;
  margin-top: ${({ theme }) => theme.space.md};
`;

const Emoji = styled.span`
  font-size: 5rem;
  display: inline-block;
  transform-origin: center;
`;

const Stars = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;
`;

const starTwinkle = keyframes`
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
`;

const Star = styled.div<{ delay: number; top: number; left: number }>`
  position: absolute;
  width: 4px;
  height: 4px;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  top: ${props => props.top}%;
  left: ${props => props.left}%;
  animation: ${starTwinkle} 1.5s ease-in-out infinite;
  animation-delay: ${props => props.delay}s;
`;

interface SplashScreenProps {
  onFinish: () => void;
}

const SplashScreen: React.FC<SplashScreenProps> = ({ onFinish }: SplashScreenProps) => {
  const [isExiting, setIsExiting] = useState(false);

  useEffect(() => {
    const timer: NodeJS.Timeout = setTimeout(() => {
      setIsExiting(true);
      setTimeout(onFinish, 500);
    }, 3000);

    return () => clearTimeout(timer);
  }, [onFinish]);

  const stars = Array.from({ length: 50 }, (_, i) => ({
    delay: Math.random() * 2,
    top: Math.random() * 100,
    left: Math.random() * 100
  }));

  return (
    <Container isExiting={isExiting}>
      <Stars>
        {stars.map((star, i) => (
          <Star key={i} {...star} />
        ))}
      </Stars>
      <RocketContainer>
        <Emoji>🚀</Emoji>
      </RocketContainer>
      <Title>PROSPECTORIA</Title>
      <Subtitle>Votre succès commence ici 🎯</Subtitle>
    </Container>
  );
};

export default SplashScreen;
