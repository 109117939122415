import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import styled from 'styled-components';

const PageContainer = styled.div`
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.background};
  padding: ${({ theme }) => `${theme.space['3xl']} ${theme.space.lg}`};
`;

const Content = styled.div`
  max-width: 800px;
  margin: 0 auto;
  background: white;
  padding: ${({ theme }) => theme.space.xl};
  border-radius: ${({ theme }) => theme.radii.lg};
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

const Header = styled.header`
  background: white;
  padding: ${({ theme }) => theme.space.lg};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

const BackButton = styled.button`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.sm};
  padding: ${({ theme }) => `${theme.space.sm} ${theme.space.md}`};
  border: none;
  background: none;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSizes.md};
  transition: transform ${({ theme }) => theme.transitions.fast};

  &:hover {
    transform: translateX(-5px);
  }
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.dark};
  margin-bottom: ${({ theme }) => theme.space.xl};
  font-size: ${({ theme }) => theme.fontSizes['3xl']};
`;

const Section = styled.section`
  margin-bottom: ${({ theme }) => theme.space.xl};

  h2 {
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: ${({ theme }) => theme.space.lg};
    font-size: ${({ theme }) => theme.fontSizes.xl};
  }

  p {
    color: ${({ theme }) => theme.colors.secondary};
    line-height: 1.6;
    margin-bottom: ${({ theme }) => theme.space.md};
  }
`;

const LegalPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <Header>
        <BackButton onClick={() => navigate('/')}>
          <FaArrowLeft /> Retour à l'accueil
        </BackButton>
      </Header>

      <PageContainer>
        <Content>
          <Title>Mentions Légales</Title>

          <Section>
            <h2>Éditeur du site</h2>
            <p>PROSPECTORIA</p>
            <p>Adresse : [Adresse à compléter]</p>
            <p>Email : contact@prospectoria.fr</p>
            <p>SIRET : [Numéro à compléter]</p>
          </Section>

          <Section>
            <h2>Hébergement</h2>
            <p>Le site est hébergé par Netlify</p>
            <p>Adresse : 2325 3rd Street, Suite 215, San Francisco, California 94107</p>
          </Section>

          <Section>
            <h2>Propriété intellectuelle</h2>
            <p>L'ensemble du site et chacun de ses éléments sont protégés par le droit de la propriété intellectuelle.</p>
          </Section>

          <Section>
            <h2>Responsabilité</h2>
            <p>PROSPECTORIA ne peut garantir l'exactitude ou l'exhaustivité des informations présentées sur le site.</p>
          </Section>
        </Content>
      </PageContainer>
    </>
  );
};

export default LegalPage;
