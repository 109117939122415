import React from 'react';
import LegalLayout, { Section } from '../../components/LegalLayout';

const PrivacyPage: React.FC = () => {
  return (
    <LegalLayout title="Politique de Confidentialité">
      <Section>
        <h2>Collecte des données</h2>
        <p>Nous collectons les informations suivantes :</p>
        <ul>
          <li>Adresse email pour l'authentification</li>
          <li>Historique des recherches pour améliorer l'expérience utilisateur</li>
          <li>Favoris et suivis pour la gestion de vos prospections</li>
          <li>Données de navigation pour optimiser nos services</li>
        </ul>
      </Section>

      <Section>
        <h2>Utilisation des données</h2>
        <p>Vos données sont utilisées pour :</p>
        <ul>
          <li>Fournir et améliorer nos services</li>
          <li>Personnaliser votre expérience</li>
          <li>Gérer votre compte et vos abonnements</li>
          <li>Communiquer avec vous concernant nos services</li>
        </ul>
      </Section>

      <Section>
        <h2>Protection des données</h2>
        <p>Nous mettons en œuvre les mesures suivantes :</p>
        <ul>
          <li>Chiffrement des données sensibles</li>
          <li>Accès restreint aux données personnelles</li>
          <li>Surveillance continue de nos systèmes</li>
          <li>Mise à jour régulière de nos mesures de sécurité</li>
        </ul>
      </Section>

      <Section>
        <h2>Vos droits</h2>
        <p>Conformément au RGPD, vous disposez des droits suivants :</p>
        <ul>
          <li>Droit d'accès à vos données personnelles</li>
          <li>Droit de rectification de vos données</li>
          <li>Droit à l'effacement de vos données</li>
          <li>Droit à la portabilité de vos données</li>
          <li>Droit d'opposition au traitement</li>
        </ul>
      </Section>

      <Section>
        <h2>Conservation des données</h2>
        <p>Nous conservons vos données :</p>
        <ul>
          <li>Pendant toute la durée de votre utilisation du service</li>
          <li>Jusqu'à la suppression de votre compte</li>
          <li>Conformément aux obligations légales</li>
        </ul>
      </Section>

      <Section>
        <h2>Partage des données</h2>
        <p>Nous ne partageons vos données qu'avec :</p>
        <ul>
          <li>Nos sous-traitants techniques (hébergement, analyse)</li>
          <li>Les autorités sur demande légale</li>
          <li>Avec votre consentement explicite</li>
        </ul>
      </Section>

      <Section>
        <h2>Contact</h2>
        <p>Pour toute question concernant vos données personnelles :</p>
        <ul>
          <li>Email : privacy@prospectoria.fr</li>
          <li>Adresse : [Adresse à compléter]</li>
          <li>Téléphone : [Numéro à compléter]</li>
        </ul>
      </Section>
    </LegalLayout>
  );
};

export default PrivacyPage;
