import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signInWithPopup,
    signOut,
    sendPasswordResetEmail,
    updateEmail as updateFirebaseEmail,
    updatePassword as updateFirebasePassword,
    User,
    onAuthStateChanged
} from 'firebase/auth';
import { auth, googleProvider } from '../config/firebase';
import { userService } from '../services/userService';

export interface AuthUser {
    uid: string;
    email: string | null;
    isPremium: boolean;
}

interface AuthContextType {
    currentUser: AuthUser | null;
    login: (email: string, password: string) => Promise<void>;
    loginWithGoogle: () => Promise<void>;
    signup: (email: string, password: string) => Promise<void>;
    logout: () => Promise<void>;
    resetPassword: (email: string) => Promise<void>;
    updateEmail: (email: string) => Promise<void>;
    updatePassword: (password: string) => Promise<void>;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<AuthUser | null>(null);
    const [loading, setLoading] = useState(true);

    console.log('AuthProvider rendering', { loading, currentUser });

    const updateUserWithPremiumStatus = async (user: User) => {
        try {
            console.log('Updating user premium status');
            const userData = await userService.getUserData(user.uid);
            setCurrentUser({
                uid: user.uid,
                email: user.email,
                isPremium: userData.isPremium || false
            });
        } catch (error) {
            console.error('Error fetching user premium status:', error);
            setCurrentUser({
                uid: user.uid,
                email: user.email,
                isPremium: false
            });
        }
    };

    const signup = async (email: string, password: string) => {
        const result = await createUserWithEmailAndPassword(auth, email, password);
        if (result.user) {
            await updateUserWithPremiumStatus(result.user);
        }
    };

    const login = async (email: string, password: string) => {
        const result = await signInWithEmailAndPassword(auth, email, password);
        if (result.user) {
            await updateUserWithPremiumStatus(result.user);
        }
    };

    const loginWithGoogle = async () => {
        const result = await signInWithPopup(auth, googleProvider);
        if (result.user) {
            await updateUserWithPremiumStatus(result.user);
        }
    };

    const logout = async () => {
        await signOut(auth);
        setCurrentUser(null);
    };

    const resetPassword = (email: string) => {
        return sendPasswordResetEmail(auth, email);
    };

    const updateEmail = async (email: string) => {
        if (!auth.currentUser) throw new Error('No user logged in');
        await updateFirebaseEmail(auth.currentUser, email);
        setCurrentUser(prev => prev ? { ...prev, email } : null);
    };

    const updatePassword = async (password: string) => {
        if (!auth.currentUser) throw new Error('No user logged in');
        await updateFirebasePassword(auth.currentUser, password);
    };

    useEffect(() => {
        console.log('Setting up auth state listener');
        const unsubscribe = onAuthStateChanged(auth, async (user: User | null) => {
            console.log('Auth state changed:', user?.email);
            if (user) {
                await updateUserWithPremiumStatus(user);
            } else {
                setCurrentUser(null);
            }
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value = {
        currentUser,
        login,
        loginWithGoogle,
        signup,
        logout,
        resetPassword,
        updateEmail,
        updatePassword
    };

    console.log('AuthProvider value:', value);

    if (loading) {
        console.log('Auth still loading');
        return <div>Loading auth...</div>;
    }

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};
