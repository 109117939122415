export type Feature = 
    | 'Mode recherche ciblée'
    | 'Mode recherche générale'
    | 'Mode estimation'
    | 'Visualisation sur carte'
    | 'Tolérance désactivée'
    | 'Tolérance configurable'
    | 'Export PDF'
    | 'Envoi par email'
    | 'Résultats illimités'
    | 'Favoris illimités'
    | 'Prospects illimités'
    | 'Statistiques avancées';

export interface UserLimits {
    name: string;
    searchesPerDay: number;
    maxResults: number;
    maxFavorites: number;
    maxProspects: number;
    features: Feature[];
    toleranceRange?: {
        min: number;
        max: number;
    };
}

export const USER_LIMITS: Record<'FREE' | 'PREMIUM', UserLimits> = {
    FREE: {
        name: 'Gratuit',
        searchesPerDay: 3,
        maxResults: 5,
        maxFavorites: 10,
        maxProspects: 20,
        toleranceRange: {
            min: 0,
            max: 3
        },
        features: [
            'Mode recherche ciblée',
            'Visualisation sur carte',
            'Tolérance désactivée'
        ]
    },
    PREMIUM: {
        name: 'Premium',
        searchesPerDay: 50,
        maxResults: Infinity,
        maxFavorites: 500,
        maxProspects: Infinity,
        toleranceRange: {
            min: 0,
            max: 10
        },
        features: [
            'Mode recherche ciblée',
            'Mode recherche générale',
            'Mode estimation',
            'Résultats illimités',
            'Tolérance configurable',
            'Export PDF',
            'Envoi par email',
            'Favoris illimités',
            'Prospects illimités',
            'Statistiques avancées'
        ]
    }
};

// Fonction utilitaire pour vérifier si un utilisateur a atteint ses limites
export const hasReachedLimit = (
    currentCount: number,
    isPremium: boolean,
    limitType: keyof Pick<UserLimits, 'searchesPerDay' | 'maxResults' | 'maxFavorites' | 'maxProspects'>
): boolean => {
    const limits = isPremium ? USER_LIMITS.PREMIUM : USER_LIMITS.FREE;
    return currentCount >= limits[limitType];
};

// Fonction utilitaire pour vérifier si une fonctionnalité est disponible
export const hasFeatureAccess = (
    feature: Feature,
    isPremium: boolean
): boolean => {
    const limits = isPremium ? USER_LIMITS.PREMIUM : USER_LIMITS.FREE;
    return limits.features.includes(feature);
};

// Fonction utilitaire pour obtenir la limite d'une fonctionnalité
export function getLimit(isPremium: boolean, limitType: keyof Pick<UserLimits, 'searchesPerDay' | 'maxResults' | 'maxFavorites' | 'maxProspects'>): number;
export function getLimit(isPremium: boolean, limitType: 'features'): Feature[];
export function getLimit(isPremium: boolean, limitType: keyof Omit<UserLimits, 'name' | 'toleranceRange'>): number | Feature[] {
    const limits = isPremium ? USER_LIMITS.PREMIUM : USER_LIMITS.FREE;
    return limits[limitType];
}

// Fonction utilitaire pour obtenir la plage de tolérance
export const getToleranceRange = (isPremium: boolean): { min: number; max: number } => {
    const limits = isPremium ? USER_LIMITS.PREMIUM : USER_LIMITS.FREE;
    return limits.toleranceRange || { min: 0, max: 3 };
};

// Fonction utilitaire pour obtenir le pourcentage d'utilisation d'une limite
export const getLimitUsagePercentage = (
    currentCount: number,
    isPremium: boolean,
    limitType: keyof Pick<UserLimits, 'searchesPerDay' | 'maxResults' | 'maxFavorites' | 'maxProspects'>
): number => {
    const limit = getLimit(isPremium, limitType);
    if (limit === Infinity) return 0;
    return Math.round((currentCount / limit) * 100);
};
