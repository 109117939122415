import React from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import Layout from './components/Layout';
import LandingPage from './pages/LandingPage';
import FAQPage from './pages/FAQPage';
import AboutPage from './pages/AboutPage';
import LegalPage from './pages/LegalPage';
import PrivacyPage from './pages/PrivacyPage';
import CookiesPage from './pages/CookiesPage';

const LayoutWrapper: React.FC = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};

// En mode teaser, toutes les routes protégées redirigent vers la landing
const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route element={<LayoutWrapper />}>
        {/* Pages publiques */}
        <Route index element={<LandingPage />} />
        <Route path="faq" element={<FAQPage />} />
        <Route path="about" element={<AboutPage />} />
        
        {/* Pages légales */}
        <Route path="mentions-legales" element={<LegalPage />} />
        <Route path="confidentialite" element={<PrivacyPage />} />
        <Route path="cookies" element={<CookiesPage />} />

        {/* Redirection des anciennes routes vers la landing */}
        <Route path="search" element={<Navigate to="/" replace />} />
        <Route path="ai-assistant" element={<Navigate to="/" replace />} />
        <Route path="plans" element={<Navigate to="/" replace />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
};

export default AppRoutes;
