import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Navbar from '../Navbar';
import CommonFooter from '../CommonFooter';

interface LayoutProps {
  children: React.ReactNode;
}

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.background};
`;

const Main = styled.main<{ $isLandingPage: boolean }>`
  flex: 1;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-top: ${({ $isLandingPage }) => $isLandingPage ? '0' : '70px'};
`;

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  
  const isLandingPage = location.pathname === '/';
  const showFooter = !isLandingPage || location.pathname === '/';

  return (
    <Container>
      <Navbar $isLandingPage={isLandingPage} />
      <Main $isLandingPage={isLandingPage}>
        {children}
      </Main>
      {showFooter && <CommonFooter />}
    </Container>
  );
};

export default Layout;
