import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaRocket } from 'react-icons/fa';
import * as S from './styles';

interface NavbarProps {
  className?: string;
  $isLandingPage?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ 
  className, 
  $isLandingPage = false
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showEarlyAdopterModal, setShowEarlyAdopterModal] = useState(false);

  const isActive = (path: string) => location.pathname === path;

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleEarlyAdopterClick = () => {
    // Émet un événement personnalisé que la landing page écoutera
    window.dispatchEvent(new CustomEvent('openEarlyAdopterModal'));
  };

  return (
    <S.Nav className={className} $isLandingPage={$isLandingPage}>
      <S.NavSection>
        <S.Logo onClick={handleLogoClick}>PROSPECTORIA</S.Logo>
        
        <S.MainNav>
          <S.NavLink to="/faq" $isActive={isActive('/faq')}>
            FAQ
          </S.NavLink>
          <S.NavLink to="/about" $isActive={isActive('/about')}>
            Qui sommes-nous
          </S.NavLink>
        </S.MainNav>
      </S.NavSection>
      
      <S.RightSection>
        <S.EarlyAdopterButton onClick={handleEarlyAdopterClick}>
          <FaRocket />
          <span>Accès Early Adopter</span>
        </S.EarlyAdopterButton>
      </S.RightSection>
    </S.Nav>
  );
};

export default Navbar;
