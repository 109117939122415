export const theme = {
  colors: {
    primary: '#00F0FF',
    primaryDark: '#00D6E4',
    secondary: '#FF3366',
    secondaryDark: '#E62E5C',
    accent: '#FFD700',
    background: '#000000',
    backgroundLight: '#1A1A1A',
    backgroundDark: '#000000',
    success: '#4CAF50',
    warning: '#FFC107',
    danger: '#FF5252',
    text: '#FFFFFF',
    textSecondary: 'rgba(255, 255, 255, 0.8)',
    textMuted: 'rgba(255, 255, 255, 0.6)',
    border: 'rgba(255, 255, 255, 0.1)',
    cardBackground: 'rgba(255, 255, 255, 0.05)',
    overlay: 'rgba(0, 0, 0, 0.7)'
  },
  fontSizes: {
    xs: '0.75rem',      // 12px
    sm: '0.875rem',     // 14px
    md: '1rem',         // 16px
    lg: '1.25rem',      // 20px
    xl: '1.5rem',       // 24px
    '2xl': '2rem',      // 32px
    '3xl': '3rem',      // 48px
    '4xl': '4rem',      // 64px
    '5xl': '6rem',      // 96px
    '6xl': '8rem',      // 128px
    '7xl': '10rem',     // 160px
    '8xl': '12rem'      // 192px
  },
  fontWeights: {
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800
  },
  fonts: {
    heading: "'Syne', sans-serif",
    body: "'Space Grotesk', sans-serif"
  },
  space: {
    xs: '0.25rem',      // 4px
    sm: '0.5rem',       // 8px
    md: '1rem',         // 16px
    lg: '1.5rem',       // 24px
    xl: '2rem',         // 32px
    '2xl': '3rem',      // 48px
    '3xl': '4rem',      // 64px
    '4xl': '6rem',      // 96px
    '5xl': '8rem',      // 128px
    '6xl': '12rem',     // 192px
    '7xl': '16rem'      // 256px
  },
  radii: {
    none: '0',
    sm: '4px',
    md: '8px',
    lg: '12px',
    xl: '16px',
    '2xl': '24px',
    '3xl': '32px',
    full: '9999px'
  },
  shadows: {
    sm: '0 2px 4px rgba(0, 0, 0, 0.3)',
    md: '0 4px 8px rgba(0, 0, 0, 0.4)',
    lg: '0 8px 16px rgba(0, 0, 0, 0.5)',
    xl: '0 12px 24px rgba(0, 0, 0, 0.6)',
    '2xl': '0 16px 32px rgba(0, 0, 0, 0.7)',
    '3xl': '0 20px 40px rgba(0, 0, 0, 0.8)',
    glow: {
      primary: '0 0 30px rgba(0, 240, 255, 0.3)',
      secondary: '0 0 30px rgba(255, 51, 102, 0.3)',
      accent: '0 0 30px rgba(255, 215, 0, 0.3)'
    }
  },
  transitions: {
    fast: '0.15s cubic-bezier(0.4, 0, 0.2, 1)',
    normal: '0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    slow: '0.5s cubic-bezier(0.4, 0, 0.2, 1)',
    bounce: '0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)'
  },
  breakpoints: {
    xs: '320px',
    sm: '480px',
    md: '768px',
    lg: '1024px',
    xl: '1280px',
    '2xl': '1536px',
    '3xl': '1920px'
  },
  gradients: {
    primary: 'linear-gradient(45deg, #00F0FF, #00D6E4)',
    secondary: 'linear-gradient(45deg, #FF3366, #E62E5C)',
    accent: 'linear-gradient(45deg, #FFD700, #FFC107)',
    dark: 'linear-gradient(180deg, #1A1A1A, #000000)',
    glass: 'linear-gradient(135deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.05))',
    glow: 'radial-gradient(circle at center, rgba(0, 240, 255, 0.1) 0%, transparent 70%)',
    hero: `
      linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.8) 0%,
        rgba(0, 0, 0, 0.6) 50%,
        rgba(0, 0, 0, 0.8) 100%
      )
    `
  },
  aspectRatios: {
    square: '1',
    video: '16/9',
    golden: '1.618'
  },
  zIndices: {
    hide: -1,
    auto: 'auto',
    base: 0,
    docked: 10,
    dropdown: 1000,
    sticky: 1100,
    banner: 1200,
    overlay: 1300,
    modal: 1400,
    popover: 1500,
    toast: 1600,
    tooltip: 1700
  }
} as const;

export type Theme = typeof theme;
