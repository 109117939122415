import { initializeApp, FirebaseApp } from 'firebase/app';
import { getFirestore, Firestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, Auth } from 'firebase/auth';
import { getAnalytics, Analytics } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAqAVrm2QzmRnwlwOQ9ToNPG3QyayC40sE",
  authDomain: "prospectoria-8a238.firebaseapp.com",
  projectId: "prospectoria-8a238",
  storageBucket: "prospectoria-8a238.firebasestorage.app",
  messagingSenderId: "478879330287",
  appId: "1:478879330287:web:6009c4ecfbebae42f8d01a",
  measurementId: "G-5V5WKW0F5W"
};

console.log('Initializing Firebase');

let app: FirebaseApp;
let analytics: Analytics;
let db: Firestore;
let auth: Auth;
let googleProvider: GoogleAuthProvider;

try {
  // Initialize Firebase
  app = initializeApp(firebaseConfig);
  console.log('Firebase initialized successfully');

  // Initialize services
  analytics = getAnalytics(app);
  db = getFirestore(app);
  auth = getAuth(app);
  googleProvider = new GoogleAuthProvider();

  // Configure Google Auth Provider
  googleProvider.setCustomParameters({
    prompt: 'select_account'
  });

  console.log('Firebase services initialized');
} catch (error) {
  console.error('Error initializing Firebase:', error);
  throw error;
}

export { app, analytics, db, auth, googleProvider };
