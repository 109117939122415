import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  /* Polices */
  @import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&family=Syne:wght@400;500;600;700;800&display=swap');

  /* Reset CSS */
  *, *::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  /* Variables globales */
  :root {
    --primary-color: ${({ theme }) => theme.colors.primary};
    --secondary-color: ${({ theme }) => theme.colors.secondary};
    --accent-color: ${({ theme }) => theme.colors.accent};
    --background-color: ${({ theme }) => theme.colors.background};
    --text-color: ${({ theme }) => theme.colors.text};
  }

  /* Styles de base */
  html {
    font-size: 16px;
    scroll-behavior: smooth;
    background-color: ${({ theme }) => theme.colors.background};
    overflow-x: hidden;
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.colors.primary} ${({ theme }) => theme.colors.backgroundDark};
  }

  body {
    font-family: ${({ theme }) => theme.fonts.body};
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    line-height: 1.6;
    overflow-x: hidden;
    min-height: 100vh;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Fond avec gradient subtil */
    &::before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: 
        radial-gradient(circle at 20% 30%, rgba(0, 240, 255, 0.05) 0%, transparent 70%),
        radial-gradient(circle at 80% 70%, rgba(255, 51, 102, 0.05) 0%, transparent 70%);
      pointer-events: none;
      z-index: -1;
    }
  }

  /* Typographie */
  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ theme }) => theme.fonts.heading};
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    line-height: 1.1;
    margin-bottom: ${({ theme }) => theme.space.lg};
    letter-spacing: -0.02em;
  }

  h1 {
    font-size: clamp(3rem, 8vw, 8rem);
    font-weight: ${({ theme }) => theme.fontWeights.extrabold};
    letter-spacing: -0.03em;
  }

  h2 {
    font-size: clamp(2.5rem, 6vw, 6rem);
    letter-spacing: -0.02em;
  }

  h3 {
    font-size: clamp(2rem, 4vw, 4rem);
    letter-spacing: -0.02em;
  }

  p {
    font-size: clamp(1rem, 1.5vw, 1.25rem);
    margin-bottom: ${({ theme }) => theme.space.md};
    line-height: 1.8;
  }

  /* Liens */
  a {
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: none;
    transition: all ${({ theme }) => theme.transitions.normal};
    position: relative;

    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
    }

    &::after {
      content: '';
      position: absolute;
      bottom: -2px;
      left: 0;
      width: 100%;
      height: 1px;
      background: ${({ theme }) => theme.gradients.primary};
      transform: scaleX(0);
      transform-origin: right;
      transition: transform ${({ theme }) => theme.transitions.normal};
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: left;
    }
  }

  /* Boutons */
  button {
    font-family: ${({ theme }) => theme.fonts.body};
    border: none;
    cursor: pointer;
    transition: all ${({ theme }) => theme.transitions.normal};
    position: relative;
    overflow: hidden;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        120deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
      );
      transition: ${({ theme }) => theme.transitions.normal};
    }

    &:hover::before {
      left: 100%;
    }
  }

  /* Scrollbar personnalisée */
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.backgroundDark};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.primary};
    border-radius: ${({ theme }) => theme.radii.full};
    
    &:hover {
      background: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  /* Sélection de texte */
  ::selection {
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.background};
  }

  /* Classes utilitaires */
  .container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 ${({ theme }) => theme.space['4xl']};
  }

  .text-gradient {
    background: ${({ theme }) => theme.gradients.primary};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .glass-effect {
    background: ${({ theme }) => theme.gradients.glass};
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }

  /* Animations */
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }

  @keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.05); }
    100% { transform: scale(1); }
  }

  /* Classes d'animation */
  .fade-in {
    animation: fadeIn 0.5s ease-out;
  }

  .slide-up {
    animation: slideUp 0.5s ease-out;
  }

  .pulse {
    animation: pulse 2s infinite;
  }

  /* Responsive */
  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    html {
      font-size: 14px;
    }

    .container {
      padding: 0 ${({ theme }) => theme.space.xl};
    }
  }

  /* Transitions de page */
  .page-transition-enter {
    opacity: 0;
    transform: translateY(20px);
  }

  .page-transition-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.5s, transform 0.5s;
  }

  .page-transition-exit {
    opacity: 1;
    transform: translateY(0);
  }

  .page-transition-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.5s, transform 0.5s;
  }
`;
