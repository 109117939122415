import React, { useEffect, useState } from 'react';
import { landingContent } from '../../content/landing';
import { FaCheck, FaCrown } from 'react-icons/fa';
import { useNumberAnimation } from '../../hooks/useNumberAnimation';
import { EarlyAdopterModal } from '../../components/EarlyAdopterModal';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Rellax from 'rellax';
import * as S from './styles';

export const LandingPage: React.FC = () => {
  const { sections } = landingContent;
  const [showEarlyAdopterModal, setShowEarlyAdopterModal] = useState(false);

  // Animation du compteur de parcelles
  const { value: parcellesCount } = useNumberAnimation(
    parseInt(sections.stats.number),
    3000,
    0
  );

  useEffect(() => {
    // Initialisation de AOS
    AOS.init({
      duration: 1000,
      once: true,
      offset: 100,
      easing: 'ease-out-cubic'
    });

    // Initialisation de Rellax pour l'effet parallax
    const rellaxInstance = new Rellax('.rellax', {
      speed: -2,
      center: true,
      round: true,
      vertical: true,
      horizontal: false
    });

    // Enable smooth scrolling
    document.documentElement.style.scrollBehavior = 'smooth';

    // Écoute l'événement d'ouverture de la modal depuis la navbar
    const handleOpenModal = () => setShowEarlyAdopterModal(true);
    window.addEventListener('openEarlyAdopterModal', handleOpenModal);

    return () => {
      rellaxInstance.destroy();
      document.documentElement.style.scrollBehavior = '';
      window.removeEventListener('openEarlyAdopterModal', handleOpenModal);
    };
  }, []);

  const handleCtaClick = () => {
    setShowEarlyAdopterModal(true);
  };

  return (
    <S.Container>
      {/* Hero Section */}
      <S.HeroSection>
        <S.ParallaxBackground className="rellax" data-rellax-speed="-8" />
        <S.HeroContent>
          <S.HeroTitle className="glitch" data-text={sections.hero.title}>
            {sections.hero.title}
          </S.HeroTitle>
          <S.HeroSubtitle data-aos="fade-up" data-aos-delay="200">
            {sections.hero.subtitle}
          </S.HeroSubtitle>
          <S.HeroActions>
            <S.Button 
              onClick={handleCtaClick}
              data-aos="fade-up" 
              data-aos-delay="400"
            >
              {sections.hero.cta.loggedOut}
            </S.Button>
            <S.LaunchOffer data-aos="fade-up" data-aos-delay="600">
              <FaCrown />
              {sections.hero.launchOffer.text}
            </S.LaunchOffer>
          </S.HeroActions>
        </S.HeroContent>
        <S.ScrollIndicator />
      </S.HeroSection>

      {/* Stats Section */}
      <S.Section $dark>
        <S.SectionInner>
          <S.SectionTitle data-aos="fade-up">
            {sections.stats.text}
          </S.SectionTitle>
          <S.StatsNumber 
            className="rellax gradient-text" 
            data-rellax-speed="1"
            data-aos="zoom-in"
          >
            {parcellesCount.toLocaleString('fr-FR')}
          </S.StatsNumber>
          <S.SectionText data-aos="fade-up">
            {sections.stats.subtext}
          </S.SectionText>
        </S.SectionInner>
      </S.Section>

      {/* Features Section */}
      <S.Section>
        <S.SectionInner>
          <S.SectionTitle data-aos="fade-up">
            {sections.features.title}
          </S.SectionTitle>
          <S.Grid>
            {sections.features.blocks.map((feature, index) => (
              <S.FeatureCard 
                key={index}
                onClick={handleCtaClick}
                $isPremium={feature.isPremium || false}
                data-aos="fade-up"
                data-aos-delay={index * 100}
                className="tilt-effect"
              >
                <S.FeatureIcon>{feature.icon}</S.FeatureIcon>
                <S.FeatureTitle>
                  {feature.title}
                  {feature.isPremium && <FaCrown />}
                </S.FeatureTitle>
                <S.FeatureDescription>
                  {feature.description}
                </S.FeatureDescription>
              </S.FeatureCard>
            ))}
          </S.Grid>
        </S.SectionInner>
      </S.Section>

      {/* Trust Section */}
      <S.Section $dark>
        <S.SectionInner>
          <S.SectionTitle data-aos="fade-up">
            {sections.trust.title}
          </S.SectionTitle>
          <S.SectionText data-aos="fade-up">
            {sections.trust.text}
          </S.SectionText>
          <S.TrustGrid>
            {sections.trust.indicators.map((indicator, index) => (
              <S.TrustItem 
                key={index}
                data-aos="fade-up"
                data-aos-delay={index * 100}
                className="tilt-effect"
              >
                <FaCheck /> {indicator}
              </S.TrustItem>
            ))}
          </S.TrustGrid>
        </S.SectionInner>
      </S.Section>

      {/* Preview Section */}
      <S.Section>
        <S.SectionInner>
          <S.SectionTitle data-aos="fade-up">
            {sections.preview.title}
          </S.SectionTitle>
          <S.SectionText data-aos="fade-up">
            {sections.preview.text}
          </S.SectionText>
          <S.Button 
            onClick={handleCtaClick}
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {sections.preview.cta.loggedOut}
          </S.Button>
        </S.SectionInner>
      </S.Section>

      {/* Final CTA Section */}
      <S.Section $dark>
        <S.SectionInner>
          <S.SectionTitle data-aos="fade-up">
            {sections.finalCta.title}
          </S.SectionTitle>
          <S.SectionText data-aos="fade-up">
            {sections.finalCta.text}
          </S.SectionText>
          <S.Button 
            onClick={handleCtaClick}
            data-aos="fade-up"
            data-aos-delay="200"
          >
            {sections.finalCta.cta.loggedOut}
          </S.Button>
        </S.SectionInner>
      </S.Section>

      <EarlyAdopterModal
        isOpen={showEarlyAdopterModal}
        onClose={() => setShowEarlyAdopterModal(false)}
      />
    </S.Container>
  );
};

export default LandingPage;
