import React from 'react';
import styled from 'styled-components';
import { FaShieldAlt, FaChartLine, FaUsers } from 'react-icons/fa';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.space.xl};
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.xxl};
  margin-bottom: ${({ theme }) => theme.space.xl};
`;

const Section = styled.section`
  margin-bottom: ${({ theme }) => theme.space.xl};
`;

const SectionTitle = styled.h2`
  color: ${({ theme }) => theme.colors.primaryDark};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  margin-bottom: ${({ theme }) => theme.space.lg};
`;

const Text = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  line-height: 1.6;
  margin-bottom: ${({ theme }) => theme.space.lg};
`;

const ValuesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: ${({ theme }) => theme.space.xl};
  margin-top: ${({ theme }) => theme.space.xl};
`;

const ValueCard = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii.lg};
  padding: ${({ theme }) => theme.space.xl};
  box-shadow: ${({ theme }) => theme.shadows.sm};
  text-align: center;
`;

const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.xxxl};
  margin-bottom: ${({ theme }) => theme.space.md};
`;

const ValueTitle = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.lg};
  margin-bottom: ${({ theme }) => theme.space.md};
`;

const ValueText = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  line-height: 1.5;
`;

const AboutPage: React.FC = () => {
  const values = [
    {
      icon: <FaShieldAlt />,
      title: "Fiabilité",
      description: "Des données cadastrales mises à jour quotidiennement pour garantir la précision de vos recherches."
    },
    {
      icon: <FaChartLine />,
      title: "Performance",
      description: "Des outils optimisés pour maximiser votre efficacité et développer votre activité."
    },
    {
      icon: <FaUsers />,
      title: "Proximité",
      description: "Un service conçu par un professionnel pour répondre aux besoins concrets des professionnels."
    }
  ];

  return (
    <PageContainer>
      <Title>Qui sommes-nous ?</Title>

      <Section>
        <SectionTitle>Notre Mission</SectionTitle>
        <Text>
          PROSPECTORIA est né d'une situation bien connue des professionnels de l'immobilier : retrouver l'adresse
          exacte d'une parcelle ou identifier des opportunités à partir d'annonces incomplètes. Une tâche souvent
          chronophage et essentielle pour la prospection et la rentrée de mandats. Passionné par la technologie et
          l'innovation, notre créateur, Nicolas Follezou, a développé une solution informatique pour automatiser ces
          recherches.
        </Text>
        <Text>
          Aujourd'hui, PROSPECTORIA est bien plus qu'une idée : c'est une plateforme SaaS conçue pour optimiser et
          simplifier le travail des professionnels, leur permettant de se concentrer sur leur cœur de métier.
        </Text>
      </Section>

      <Section>
        <SectionTitle>Notre Vision</SectionTitle>
        <Text>
          Nous croyons que chaque professionnel mérite des outils performants, précis et conçus pour optimiser leur
          temps et leur organisation. Notre objectif est de devenir une référence dans le domaine de la prospection
          immobilière, tout en restant à l'écoute des besoins des utilisateurs grâce à des retours et sondages réguliers.
        </Text>
      </Section>

      <Section>
        <SectionTitle>Nos Valeurs</SectionTitle>
        <ValuesGrid>
          {values.map((value, index) => (
            <ValueCard key={index}>
              <IconWrapper>{value.icon}</IconWrapper>
              <ValueTitle>{value.title}</ValueTitle>
              <ValueText>{value.description}</ValueText>
            </ValueCard>
          ))}
        </ValuesGrid>
      </Section>
    </PageContainer>
  );
};

export default AboutPage;
