import { useState, useEffect } from 'react';

export const useNumberAnimation = (
  endValue: number,
  duration: number = 2000,
  startValue: number = 0
) => {
  const [currentValue, setCurrentValue] = useState(startValue);
  const [isAnimating, setIsAnimating] = useState(true);

  useEffect(() => {
    const startTime = Date.now();
    const endTime = startTime + duration;

    const animate = () => {
      const now = Date.now();
      const progress = Math.min(1, (now - startTime) / duration);
      
      // Fonction d'easing pour une animation plus naturelle
      const easeOutQuart = 1 - Math.pow(1 - progress, 4);
      
      const currentNumber = Math.floor(startValue + (endValue - startValue) * easeOutQuart);
      setCurrentValue(currentNumber);

      if (now < endTime) {
        requestAnimationFrame(animate);
      } else {
        setCurrentValue(endValue);
        setIsAnimating(false);
      }
    };

    requestAnimationFrame(animate);

    return () => {
      setIsAnimating(false);
    };
  }, [endValue, duration, startValue]);

  return { value: currentValue, isAnimating };
};
