import { db } from '../config/firebase';
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';

const EARLY_ADOPTERS_COLLECTION = 'earlyAdopters';
const EARLY_ADOPTER_LIMIT = 1000;

export interface EarlyAdopter {
  email: string;
  registeredAt: Date;
  source?: string;
}

export const earlyAdoptersService = {
  async registerEarlyAdopter(email: string, source?: string): Promise<{ success: boolean; message: string }> {
    try {
      // Vérifier si l'email existe déjà
      const emailQuery = query(
        collection(db, EARLY_ADOPTERS_COLLECTION),
        where('email', '==', email)
      );
      const existingDocs = await getDocs(emailQuery);
      
      if (!existingDocs.empty) {
        return {
          success: false,
          message: 'Cet email est déjà enregistré pour les tarifs de lancement !'
        };
      }

      // Vérifier si la limite est atteinte
      const allDocsQuery = query(collection(db, EARLY_ADOPTERS_COLLECTION));
      const snapshot = await getDocs(allDocsQuery);
      
      if (snapshot.size >= EARLY_ADOPTER_LIMIT) {
        return {
          success: false,
          message: 'Désolé, la limite des 1000 premiers inscrits est atteinte !'
        };
      }

      // Ajouter le nouvel early adopter
      await addDoc(collection(db, EARLY_ADOPTERS_COLLECTION), {
        email,
        registeredAt: new Date(),
        source: source || 'landing_page'
      });

      return {
        success: true,
        message: 'Super ! Vous êtes inscrit pour bénéficier des tarifs de lancement 🚀'
      };
    } catch (error) {
      console.error('Error registering early adopter:', error);
      return {
        success: false,
        message: 'Une erreur est survenue lors de l\'inscription. Veuillez réessayer.'
      };
    }
  },

  async getRemainingSlots(): Promise<number> {
    try {
      const query = collection(db, EARLY_ADOPTERS_COLLECTION);
      const snapshot = await getDocs(query);
      return Math.max(0, EARLY_ADOPTER_LIMIT - snapshot.size);
    } catch (error) {
      console.error('Error getting remaining slots:', error);
      return 0;
    }
  }
};
