import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaCrown, FaCheck, FaTimes, FaRocket, FaGift, FaStar, FaHourglassHalf } from 'react-icons/fa';
import { earlyAdoptersService } from '../../services/earlyAdoptersService';

const slideIn = keyframes`
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const pulse = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
`;

const shine = keyframes`
  0% { background-position: 200% center; }
  100% { background-position: -200% center; }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(10px);
  padding: 20px;
`;

const ModalContent = styled.div`
  background: linear-gradient(135deg, rgba(10, 10, 10, 0.95), rgba(30, 30, 30, 0.95));
  padding: 3rem;
  border-radius: 20px;
  max-width: 600px;
  width: 100%;
  position: relative;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4),
              0 0 100px rgba(0, 240, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  animation: ${slideIn} 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    background: linear-gradient(90deg, var(--primary-color), var(--secondary-color));
    opacity: 0.5;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.6);
  transition: all 0.3s ease;

  &:hover {
    color: var(--primary-color);
    transform: rotate(90deg);
  }
`;

const Title = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: white;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: 'Syne', sans-serif;
  font-weight: 800;
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% auto;
  animation: ${shine} 3s linear infinite;

  svg {
    color: var(--accent-color);
    -webkit-text-fill-color: var(--accent-color);
  }
`;

const Subtitle = styled.div`
  color: var(--accent-color);
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  animation: ${pulse} 2s infinite;
`;

const Description = styled.p`
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 2rem;
  line-height: 1.8;
  font-size: 1.1rem;
  font-family: 'Space Grotesk', sans-serif;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const Input = styled.input`
  padding: 1rem 1.5rem;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  font-size: 1.1rem;
  color: white;
  transition: all 0.3s ease;
  font-family: 'Space Grotesk', sans-serif;

  &:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 20px rgba(0, 240, 255, 0.1);
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.4);
  }
`;

const SubmitButton = styled.button`
  background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
  color: var(--background-color);
  padding: 1.2rem;
  border: none;
  border-radius: 12px;
  font-size: 1.1rem;
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  font-family: 'Space Grotesk', sans-serif;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    transition: 0.5s;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);

    &::before {
      left: 100%;
    }
  }

  &:disabled {
    background: rgba(255, 255, 255, 0.1);
    cursor: not-allowed;
    transform: none;
  }
`;

const Benefits = styled.div`
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
`;

const Benefit = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 12px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    background: rgba(255, 255, 255, 0.08);
  }
`;

const BenefitIcon = styled.div`
  color: var(--primary-color);
  font-size: 1.2rem;
  margin-top: 0.2rem;
`;

const BenefitContent = styled.div`
  flex: 1;
`;

const BenefitTitle = styled.div`
  color: white;
  font-weight: bold;
  margin-bottom: 0.2rem;
`;

const BenefitDescription = styled.div`
  color: rgba(255, 255, 255, 0.6);
  font-size: 0.9rem;
`;

const Message = styled.div<{ isError?: boolean }>`
  padding: 1rem;
  margin-top: 1.5rem;
  border-radius: 12px;
  background: ${props => props.isError ? 
    'rgba(255, 51, 102, 0.1)' : 
    'rgba(0, 240, 255, 0.1)'};
  color: ${props => props.isError ? 
    'var(--secondary-color)' : 
    'var(--primary-color)'};
  text-align: center;
  font-weight: 500;
  border: 1px solid ${props => props.isError ? 
    'var(--secondary-color)' : 
    'var(--primary-color)'};
  animation: ${slideIn} 0.3s ease;
`;

const Counter = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: var(--accent-color);
  font-size: 0.9rem;
  margin-top: 1rem;
  justify-content: center;
`;

interface EarlyAdopterModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EarlyAdopterModal: React.FC<EarlyAdopterModalProps> = ({ isOpen, onClose }) => {
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState<{ text: string; isError: boolean } | null>(null);
  const [remainingSlots, setRemainingSlots] = useState<number | null>(null);

  useEffect(() => {
    if (isOpen) {
      earlyAdoptersService.getRemainingSlots().then(setRemainingSlots);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setMessage(null);

    try {
      const result = await earlyAdoptersService.registerEarlyAdopter(email);
      setMessage({
        text: result.message,
        isError: !result.success
      });

      if (result.success) {
        setTimeout(onClose, 3000);
      }
    } catch (error) {
      setMessage({
        text: 'Une erreur est survenue. Veuillez réessayer.',
        isError: true
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContent onClick={e => e.stopPropagation()}>
        <CloseButton onClick={onClose}>
          <FaTimes />
        </CloseButton>

        <Title>
          <FaCrown /> Offre Early Adopter
        </Title>

        <Subtitle>
          <FaRocket /> Accès Privilégié
        </Subtitle>

        <Description>
          Rejoignez l'élite des professionnels de l'immobilier. Soyez parmi les premiers 
          à révolutionner votre approche de la prospection avec PROSPECTORIA.
        </Description>

        <Benefits>
          <Benefit>
            <BenefitIcon><FaCrown /></BenefitIcon>
            <BenefitContent>
              <BenefitTitle>Tarif Préférentiel</BenefitTitle>
              <BenefitDescription>29.99€/mois au lieu de 49.99€, garanti à vie</BenefitDescription>
            </BenefitContent>
          </Benefit>
          <Benefit>
            <BenefitIcon><FaRocket /></BenefitIcon>
            <BenefitContent>
              <BenefitTitle>Accès Prioritaire</BenefitTitle>
              <BenefitDescription>Nouvelles fonctionnalités en avant-première</BenefitDescription>
            </BenefitContent>
          </Benefit>
          <Benefit>
            <BenefitIcon><FaGift /></BenefitIcon>
            <BenefitContent>
              <BenefitTitle>Formation Offerte</BenefitTitle>
              <BenefitDescription>Formation exclusive valeur 499€</BenefitDescription>
            </BenefitContent>
          </Benefit>
          <Benefit>
            <BenefitIcon><FaStar /></BenefitIcon>
            <BenefitContent>
              <BenefitTitle>Support Premium</BenefitTitle>
              <BenefitDescription>Assistance dédiée 7j/7</BenefitDescription>
            </BenefitContent>
          </Benefit>
        </Benefits>

        <Form onSubmit={handleSubmit}>
          <Input
            type="email"
            placeholder="Votre email professionnel"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
          <SubmitButton type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Inscription...' : 'Réserver mon accès prioritaire'}
          </SubmitButton>
        </Form>

        {remainingSlots !== null && (
          <Counter>
            <FaHourglassHalf /> Plus que {remainingSlots} places au tarif early adopter
          </Counter>
        )}

        {message && (
          <Message isError={message.isError}>
            {message.text}
          </Message>
        )}
      </ModalContent>
    </ModalOverlay>
  );
};
