import React from 'react';
import styled from 'styled-components';

const PageContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.space.xl};
`;

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.primary};
  font-size: ${({ theme }) => theme.fontSizes.xxl};
  margin-bottom: ${({ theme }) => theme.space.xl};
`;

const FAQSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.space.lg};
`;

const Question = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: ${({ theme }) => theme.radii.lg};
  padding: ${({ theme }) => theme.space.lg};
  box-shadow: ${({ theme }) => theme.shadows.sm};
`;

const QuestionTitle = styled.h3`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: ${({ theme }) => theme.space.md};
  font-size: ${({ theme }) => theme.fontSizes.lg};
`;

const Answer = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  line-height: 1.6;
`;

const FAQPage: React.FC = () => {
  const faqs = [
    {
      question: "Comment fonctionne PROSPECTORIA ?",
      answer: "PROSPECTORIA vous permet de transformer une simple surface de parcelle en une adresse exploitable. Entrez la surface et la localisation approximative pour découvrir l'adresse exacte en quelques clics."
    },
    {
      question: "Quelle est la différence entre les versions gratuite et premium ?",
      answer: "La version gratuite permet d'effectuer 3 recherches par jour avec des résultats limités. La version premium offre des recherches illimitées, l'exportation en PDF et un suivi illimité des prospects."
    },
    {
      question: "Les données sont-elles à jour ?",
      answer: "Oui, nos données cadastrales sont mises à jour quotidiennement pour garantir la plus grande fiabilité possible dans vos recherches."
    },
    {
      question: "Mes données sont-elles sécurisées ?",
      answer: "Absolument ! Nous utilisons des protocoles de sécurité avancés pour garantir la confidentialité et la protection de vos informations personnelles et professionnelles."
    },
    {
      question: "Comment modifier mes informations personnelles ?",
      answer: "Rendez-vous dans la section 'Mon compte' pour mettre à jour vos informations personnelles, y compris votre email et votre mot de passe."
    },
    {
      question: "Puis-je exporter mes résultats ?",
      answer: "Les utilisateurs premium peuvent exporter leurs résultats au format PDF, incluant les informations détaillées des parcelles et les vues Street View."
    },
    {
      question: "Combien de recherches puis-je effectuer en version gratuite ?",
      answer: "La version gratuite permet d'effectuer jusqu'à 3 recherches par jour. Pour des recherches illimitées, pensez à passer à la version premium."
    },
    {
      question: "Comment puis-je récupérer mon mot de passe oublié ?",
      answer: "Cliquez sur 'Mot de passe oublié' sur la page de connexion et suivez les instructions pour réinitialiser votre mot de passe via votre email."
    },
    {
      question: "Quels navigateurs sont pris en charge ?",
      answer: "PROSPECTORIA est compatible avec les navigateurs modernes tels que Chrome, Firefox, Edge et Safari. Pour une expérience optimale, utilisez la dernière version de votre navigateur."
    },
    {
      question: "Puis-je utiliser PROSPECTORIA en dehors de la France ?",
      answer: "Pour le moment, PROSPECTORIA est optimisé pour les recherches en France uniquement. Nous travaillons à une éventuelle extension."
    },
    {
      question: "Comment optimiser mes recherches ?",
      answer: "Utilisez des surfaces exactes ou des plages de tolérance adaptées pour obtenir des résultats précis. Pensez également à sauvegarder vos recherches pour un accès ultérieur."
    },
    {
      question: "Quels types de biens sont inclus dans les résultats ?",
      answer: "Les résultats incluent toutes les parcelles cadastrales, qu'il s'agisse de terrains, de maisons individuelles ou d'immeubles."
    },
    {
      question: "Comment puis-je contribuer à l'amélioration de PROSPECTORIA ?",
      answer: "Vos retours sont précieux ! Contactez-nous via la page 'Contact' pour partager vos idées ou signaler des problèmes."
    }
  ];

  return (
    <PageContainer>
      <Title>Questions Fréquentes</Title>
      <FAQSection>
        {faqs.map((faq, index) => (
          <Question key={index}>
            <QuestionTitle>{faq.question}</QuestionTitle>
            <Answer>{faq.answer}</Answer>
          </Question>
        ))}
      </FAQSection>
    </PageContainer>
  );
};

export default FAQPage;
