import styled, { keyframes } from 'styled-components';

const float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-20px); }
  100% { transform: translateY(0px); }
`;

const shine = keyframes`
  0% { background-position: 200% center; }
  100% { background-position: -200% center; }
`;

const scroll = keyframes`
  0% { transform: translateY(0); opacity: 1; }
  50% { transform: translateY(10px); opacity: 0.5; }
  100% { transform: translateY(0); opacity: 1; }
`;

export const Container = styled.div`
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.background};
  overflow-x: hidden;
`;

export const Section = styled.section<{ $dark?: boolean }>`
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.space['5xl']} 0;
  background: ${({ theme, $dark }) => 
    $dark ? theme.colors.backgroundDark : theme.colors.background};
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: ${({ theme }) => theme.gradients.primary};
    opacity: 0.1;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: ${({ theme }) => theme.gradients.primary};
    opacity: 0.1;
  }
`;

export const SectionInner = styled.div`
  max-width: 1400px;
  width: 100%;
  margin: 0 auto;
  padding: 0 ${({ theme }) => theme.space['4xl']};
  position: relative;
  z-index: 1;
`;

export const HeroSection = styled.section`
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.text};
  padding: 0 ${({ theme }) => theme.space['4xl']};
  overflow: hidden;
  margin-top: -70px; // Compenser la hauteur de la navbar

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(0deg, ${({ theme }) => theme.colors.background} 0%, transparent 100%);
    z-index: 1;
  }
`;

export const HeroContent = styled.div`
  position: relative;
  z-index: 2;
  max-width: 1400px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform-style: preserve-3d;
  will-change: transform;
  padding-top: 70px; // Compenser le margin-top négatif
`;

export const HeroTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: clamp(4rem, 8vw, 8rem);
  font-weight: ${({ theme }) => theme.fontWeights.extrabold};
  line-height: 1.1;
  margin-bottom: ${({ theme }) => theme.space['3xl']};
  text-shadow: 0 0 10px rgba(0, 240, 255, 0.5);
  text-align: center;
  background: ${({ theme }) => theme.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 400% 400%;
  animation: ${shine} 3s linear infinite;
  letter-spacing: -2px;
`;

export const HeroSubtitle = styled.p`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: clamp(1.5rem, 3vw, 2.5rem);
  margin-bottom: ${({ theme }) => theme.space['3xl']};
  line-height: 1.4;
  text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  color: ${({ theme }) => theme.colors.textSecondary};
  max-width: 900px;
  letter-spacing: -0.5px;
`;

export const HeroActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.space['2xl']};
  margin-top: ${({ theme }) => theme.space['4xl']};
`;

export const Button = styled.button`
  background: ${({ theme }) => theme.gradients.primary};
  color: ${({ theme }) => theme.colors.background};
  border: none;
  border-radius: ${({ theme }) => theme.radii.full};
  padding: ${({ theme }) => `${theme.space.xl} ${theme.space['3xl']}`};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-family: ${({ theme }) => theme.fonts.body};
  cursor: pointer;
  transition: all ${({ theme }) => theme.transitions.normal};
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      120deg,
      transparent,
      rgba(255, 255, 255, 0.3),
      transparent
    );
    transition: ${({ theme }) => theme.transitions.normal};
  }

  &:hover {
    transform: translateY(-3px);
    box-shadow: ${({ theme }) => theme.shadows.glow.primary};

    &::before {
      left: 100%;
    }
  }

  &:active {
    transform: translateY(1px);
  }
`;

export const LaunchOffer = styled.div`
  margin-top: ${({ theme }) => theme.space['2xl']};
  padding: ${({ theme }) => theme.space.xl} ${({ theme }) => theme.space['2xl']};
  background: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid ${({ theme }) => theme.colors.accent};
  border-radius: ${({ theme }) => theme.radii.lg};
  color: ${({ theme }) => theme.colors.accent};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.md};
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.3);
  backdrop-filter: blur(5px);
  transform: translateZ(0);
  animation: ${float} 6s ease-in-out infinite;
`;

export const StatsNumber = styled.div`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: clamp(4rem, 10vw, 10rem);
  font-weight: ${({ theme }) => theme.fontWeights.extrabold};
  background: ${({ theme }) => theme.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.space.xl};
  transform-style: preserve-3d;
  will-change: transform;
  text-shadow: ${({ theme }) => theme.shadows.glow.primary};
  letter-spacing: -2px;
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: ${({ theme }) => theme.space['3xl']};
  margin-top: ${({ theme }) => theme.space['4xl']};
  width: 100%;
  position: relative;
  z-index: 1;
`;

export const FeatureCard = styled.div<{ $isPremium?: boolean }>`
  background: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid ${({ theme, $isPremium }) => 
    $isPremium ? theme.colors.accent : theme.colors.border};
  border-radius: ${({ theme }) => theme.radii['3xl']};
  padding: ${({ theme }) => theme.space['4xl']};
  transition: all ${({ theme }) => theme.transitions.normal};
  cursor: pointer;
  position: relative;
  overflow: hidden;
  backdrop-filter: blur(10px);
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${({ theme, $isPremium }) => $isPremium && `
    box-shadow: ${theme.shadows.glow.accent};
  `}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.gradients.glass};
    transform: translateX(-100%);
    transition: ${({ theme }) => theme.transitions.normal};
  }

  &:hover {
    transform: translateY(-10px);
    box-shadow: ${({ theme }) => theme.shadows.xl};

    &::before {
      transform: translateX(100%);
    }
  }
`;

export const FeatureIcon = styled.div`
  font-size: ${({ theme }) => theme.fontSizes['5xl']};
  margin-bottom: ${({ theme }) => theme.space.xl};
  background: ${({ theme }) => theme.gradients.primary};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const FeatureTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: ${({ theme }) => theme.fontSizes['3xl']};
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${({ theme }) => theme.space.lg};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.sm};
  letter-spacing: -1px;

  svg {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

export const FeatureDescription = styled.p`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: ${({ theme }) => theme.fontSizes.xl};
  color: ${({ theme }) => theme.colors.textSecondary};
  line-height: 1.6;
`;

export const TrustGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.space['2xl']};
  margin-top: ${({ theme }) => theme.space['4xl']};
  width: 100%;
  position: relative;
  z-index: 1;
`;

export const TrustItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.space.lg};
  color: ${({ theme }) => theme.colors.textSecondary};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  text-align: center;
  padding: ${({ theme }) => theme.space['2xl']};
  background: ${({ theme }) => theme.colors.cardBackground};
  border: 1px solid ${({ theme }) => theme.colors.border};
  border-radius: ${({ theme }) => theme.radii.xl};
  transition: all ${({ theme }) => theme.transitions.normal};
  min-height: 200px;

  &:hover {
    transform: translateY(-5px);
    box-shadow: ${({ theme }) => theme.shadows.lg};
  }

  svg {
    color: ${({ theme }) => theme.colors.primary};
    font-size: ${({ theme }) => theme.fontSizes['3xl']};
  }
`;

export const SectionTitle = styled.h2`
  font-family: ${({ theme }) => theme.fonts.heading};
  font-size: clamp(3rem, 6vw, 6rem);
  color: ${({ theme }) => theme.colors.text};
  margin-bottom: ${({ theme }) => theme.space['4xl']};
  text-align: center;
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  letter-spacing: -1px;

  &::after {
    content: '';
    display: block;
    width: 150px;
    height: 4px;
    background: ${({ theme }) => theme.gradients.primary};
    margin: ${({ theme }) => theme.space.xl} auto 0;
    border-radius: ${({ theme }) => theme.radii.sm};
  }
`;

export const SectionText = styled.p`
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: clamp(1.25rem, 2vw, 2rem);
  color: ${({ theme }) => theme.colors.textSecondary};
  line-height: 1.8;
  margin-bottom: ${({ theme }) => theme.space['3xl']};
  text-align: center;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 1;
  letter-spacing: -0.5px;
`;

export const ScrollIndicator = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.space['4xl']};
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: 50px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 15px;
  z-index: 2;
  animation: ${scroll} 2s infinite;

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 50%;
    transform: translateX(-50%);
    width: 6px;
    height: 6px;
    background: ${({ theme }) => theme.colors.primary};
    border-radius: 50%;
  }
`;

export const ParallaxBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
              url('/banner.jpeg');
  background-size: cover;
  background-position: center;
  z-index: 0;
  transform-style: preserve-3d;
  will-change: transform;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at center, transparent 0%, ${({ theme }) => theme.colors.background} 100%);
  }
`;
